import React, { useState } from "react";
import krData from "../data/kr.json";
import SubjectItem from "../components/SubjectItem";
import "../css/homePage.css";
import { Link } from "react-router-dom";
import fundedCourse from "../data/fundedCourse/fundedCourse.json";
import FoundedCourseItem from "../components/FoundedCourseItem";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

const HomePage = () => {
  const [data] = useState(krData);

  const academicItems = data.map((item) => {
    return <SubjectItem key={item.uid} data={item}></SubjectItem>;
  });

  const fundedCourseList = fundedCourse
    .filter((data) => {
      const now = new Date().toISOString().split("T")[0];
      const targetDate = new Date(data.endDate).toISOString().split("T")[0];
      return now <= targetDate;
    })
    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    .slice(0, 5)
    .map((fundedCourseData, index) => {
      return (
        <SwiperSlide key={index}>
          <FoundedCourseItem fundedCourse={fundedCourseData} />
        </SwiperSlide>
      );
    });

  return (
    <main id="homepage">
      <section id="main-nav-container">
        <div id="funded-course-container">
          <div className="design-line"></div>
          <div>
            <ul>
              <li>곧 개강하는 국비지원과정을 확인하고 싶다면?</li>
              <li>
                <Link to={"/funded-course"}>
                  곧 개강하는 국비지원과정 보러가기 ->
                </Link>
              </li>
            </ul>
            <div></div>

            <div>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {fundedCourseList}
              </Swiper>
            </div>
          </div>
        </div>

        <div id="tranning-center-container">
          <div className="design-line"></div>
          <div>
            <ul>
              <li>좋은 학원정보들을 확인하고 싶다면?</li>
              <li>
                <Link to={"/tranining-center"}>학원 보러가기 -></Link>
              </li>
            </ul>
            <div></div>
            <div>
              <Link to={"https://www.himediaacademy.kr/"} target="_blank">
                <div>
                  <p>하이미디어아카데미</p>
                  <p>"뚜렷한 목표와 확실한 자신감을 이끌어내는 교육"</p>
                </div>
              </Link>
              <Link to={"https://www.koreaisacademy.com/"} target="_blank">
                <div>
                  <p>코리아IT아카데미</p>
                  <p>"누구든지 IT전문가가 될 수 있습니다."</p>
                </div>
              </Link>
              <Link to={"https://se.sbsart.com/"} target="_blank">
                <div>
                  <p>SBS아카데미컴퓨터아트학원</p>
                  <p>"업계 최고 수준의 교육시설을 제공합니다."</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section id="academic-container">
        <div>
          <div id="design-line"></div>
          <p>Academic</p>
        </div>
        <div>{academicItems}</div>
      </section>
    </main>
  );
};

export default HomePage;
