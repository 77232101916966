import React, { useEffect, useState } from "react";
import "../css/trainingCenterPage.css";
import { Link } from "react-router-dom";
import adTrainingCenter from "../data/trainingCenter/adTrainingCenter.json";
import trainingCenter from "../data/trainingCenter/trainingCenter.json";
import styled from "styled-components";

const AdTrainingCenterItem = styled.div`
  a > div {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: linear-gradient(
      to right,
      #218cf7 0%,
      #35a9e4 50%,
      #35a6e4 100%
    );
    padding: 16px 1px 1px 1px;
    box-sizing: border-box;
    overflow: hidden;
    transition-duration: 0.2s;
    color: black;
  }

  a > div:hover {
    transform: scale(1.02);
  }

  a > div > div {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 0px 0px 4px 4px;
    padding: 16px 12px 10px 12px;
    box-sizing: border-box;
  }

  a > div > div > #training-center-name {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  a > div > div > #slogan {
    line-height: 21px;
    font-size: 14px;
    text-align: center;
    margin-bottom: 12px;
  }

  a > div > div > #design-line {
    width: 100%;
    height: 2px;
    background-color: #35a8e4;
    margin-bottom: 4px;
  }

  a > div > div > #academy-locations {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
  }

  a > div > div > #academy-locations > p {
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
  }

  a > div > div > #academy-locations > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  a > div > div > #academy-locations > ul > li {
    background-color: #218cf7;
    color: #fff;
    padding: 10px 8px 10px 8px;
    border-radius: 4px;
  }

  a > div > div > #education-subjects > p {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
  }

  a > div > div > #education-subjects > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  a > div > div > #education-subjects > ul > li {
    background-color: #218cf7;
    color: #fff;
    padding: 10px 8px 10px 8px;
    border-radius: 4px;
  }

  @media (max-width: 480px) {
    a > div > div > #training-center-name {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 4px;
    }

    a > div > div > #slogan {
      line-height: 18px;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 4px;
    }

    a > div > div > #academy-locations {
      margin-bottom: 8px;
    }

    a > div > div > #academy-locations > p {
      font-size: 12px;
      font-weight: bold;
    }

    a > div > div > #academy-locations > ul {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }

    a > div > div > #academy-locations > ul > li {
      background-color: #218cf7;
      color: #fff;
      padding: 8px;
      border-radius: 4px;
      font-size: 12px;
    }

    a > div > div > #education-subjects > p {
      margin-bottom: 8px;
      font-size: 12px;
      font-weight: bold;
    }

    a > div > div > #education-subjects > ul {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }

    a > div > div > #education-subjects > ul > li {
      background-color: #218cf7;
      color: #fff;
      padding: 8px;
      border-radius: 4px;
      font-size: 12px;
    }
  }
`;

const TrainingCenterPage = () => {
  const [adTrainingCenterData, setAdTrainingCenterData] = useState([
    {
      uid: 0,
      trainingCenterName: "",
      imageUrl: "",
      siteUrl: "",
    },
  ]);
  const [trainingCenterData, setTrainingCenterData] = useState([
    {
      uid: 0,
      trainingCenterName: "",
      siteUrl: "",
    },
  ]);

  useEffect(() => {
    setAdTrainingCenterData(adTrainingCenter);
    setTrainingCenterData(trainingCenter);
  }, []);

  const adTrainingCenterItems = adTrainingCenterData.map((element) => {
    const academyLocationsMap = element.academyLocations?.map(
      (academyLocation) => {
        return <li>{academyLocation}</li>;
      }
    );

    const educationSubjectsMap = element.educationSubjects?.map(
      (educationSubject) => {
        return <li>{educationSubject}</li>;
      }
    );

    return (
      <AdTrainingCenterItem>
        <Link to={element.siteUrl} key={element.uid} target="_blank">
          <div className="training-center-ad-item">
            <div>
              <p id="training-center-name">{element.trainingCenterName}</p>
              <p id="slogan">{element.slogan}</p>
              <div id="design-line"></div>
              <div id="academy-locations">
                <p>지점위치</p>
                <ul>{academyLocationsMap}</ul>
              </div>
              <div id="education-subjects">
                <p>교육과목</p>
                <ul>{educationSubjectsMap}</ul>
              </div>
            </div>
          </div>
        </Link>
      </AdTrainingCenterItem>
    );
  });

  const trainingCenterItems = trainingCenterData.map((element) => {
    return (
      <Link to={element.siteUrl} key={element.uid} target="_blank">
        <div className="training-center-item">{element.trainingCenterName}</div>
      </Link>
    );
  });

  return (
    <main id="main-training-center">
      <section>
        <div>
          <div id="design-line"></div>
          <p>학원 목록</p>
        </div>
        <div id="container-training-center-ad-item">
          {adTrainingCenterItems}
        </div>

        <div id="design-line"></div>

        <div id="container-training-center-item">{trainingCenterItems}</div>
      </section>
    </main>
  );
};

export default TrainingCenterPage;
