import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CurriculumPage from "./pages/CurriculumPage";
import SelfLearningDetailPage from "./pages/SeriesPostDetailPage";
import TraningCenterPage from "./pages/TrainingCenterPage";
import SeriesListPage from "./pages/SeriesListPage";
import SeriesPostListPage from "./pages/SeriesPostListPage";
import { useEffect } from "react";
import FundedCoursePage from "./pages/FundedCoursePage";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header></Header>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/academic/:academicName" element={<CurriculumPage />} />

        <Route path="/tranining-center" element={<TraningCenterPage />} />

        <Route path="/series" element={<SeriesListPage />} />
        <Route path="/series/:seriesUid" element={<SeriesPostListPage />} />
        <Route
          path="/series/:seriesUid/:postUid"
          element={<SelfLearningDetailPage />}
        />
        <Route path="/funded-course" element={<FundedCoursePage />} />
      </Routes>
      <Footer></Footer>
    </>
  );
}

export default App;
