import React from "react";
import "../css/curriculumPage.css";
import { useParams } from "react-router-dom";
import krData from "../data/kr.json";
import CurriculumItem from "../components/CurriculumItem";

const CurriculumPage = () => {
  const { academicName } = useParams();

  const academicData = krData.filter((element) => {
    return element.title === academicName;
  });

  const result = academicData[0].curriculum.map((element, index) => {
    return <CurriculumItem key={index} data={element}></CurriculumItem>;
  });

  return (
    <main id="curriculumpage">
      <section id="academic-introduction-container">
        <p>{academicData[0].title}</p>
        <p>{academicData[0].introduction}</p>
      </section>

      <section id="academic-curriculum-container">
        <div>
          <div id="design-line"></div>
          <p>Curriculum</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {result}
        </div>
      </section>
    </main>
  );
};

export default CurriculumPage;
