import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Item = styled.div`
  height: 100%;

  & > a {
    display: block;
    height: 100%;
  }

  & > a > #funded-course-item {
    height: 100%;
    width: 384px;
    background: linear-gradient(
      to right,
      #218cf7 0%,
      #35a9e4 50%,
      #35a6e4 100%
    );
    border-radius: 8px;
    padding: 8px 1px 1px 1px;
    box-sizing: border-box;
    transition: 0.3s;
    color: black;

    &:hover {
      scale: 1.02;
    }

    & > div {
      width: 382px;
      height: 100%;
      background-color: #fff;
      border-radius: 0px 0px 8px 8px;
      padding: 0px 16px 16px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > p:first-of-type {
        font-weight: bold;
        line-height: 24px;
      }

      & > div:first-of-type {
        height: 53px;
        line-height: 53px;
        text-align: center;
        font-weight: bold;
        border-bottom: 1px solid #218cf7;
      }

      & > div:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      & > div:nth-of-type(2) > p {
      }

      & > div:nth-of-type(2) > div {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
      }

      & > div:nth-of-type(2) > div > div {
        display: inline;
        padding: 10px 8px;
        background-color: #218cf7;
        color: #fff;
        border-radius: 8px;
      }
    }
  }

  @media (max-width: 480px) {
    & > a > #funded-course-item {
      width: 100%;
      font-size: 14px;

      & > div {
        width: 100%;
      }
    }
  }
`;

const FoundedCourseItem = ({ fundedCourse }) => {
  const subjectItems = fundedCourse?.subjects.map((subject, index) => {
    return <div key={index}>{subject}</div>;
  });

  return (
    <Item>
      <Link to={fundedCourse.redirectUrl}>
        <div id="funded-course-item">
          <div>
            <div>{fundedCourse.academyName}</div>
            <p>과정명 : {fundedCourse.courseName}</p>
            <div>
              <p>배우는 과목</p>
              <div>{subjectItems}</div>
            </div>
            <p>위치 : {fundedCourse.location}</p>
            <p>개강예정일 : {fundedCourse.startDate}</p>
            <p>종강예정일 : {fundedCourse.endDate}</p>
            <p>신청방법 : {fundedCourse.applicationMethod}</p>
            <p>가격 : {fundedCourse.price}</p>
          </div>
        </div>
      </Link>
    </Item>
  );
};

export default FoundedCourseItem;
