import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Item = styled.div`
  display:flex;
  justify-content:space-between;
  flex-direction:column;
  width: 188px;
  height: 144px;
  text-align: center;
  border-radius: 8px;
  overflow:hidden;
  box-shadow:0px 0px 4px rgba(0, 0, 0, 0.25);
  transition-duration:0.2s;

  &:hover{
    transform:scale(1.03);
  }

  img{
    margin-top:24px;
    height:40px;
    object-fit: contain;
  }

  p{
    background-color: #218CF7;
    width:100%;
    height:56px;
    line-height:56px;
    color: #fff; 
    font-size: 16px;
  }

  @media (max-width: 480px) {
    width:100%;
    height:80px;
    line-height:40px;
    font-size:14px;

    img{
      height:24px;
      margin-top:8px;
      object-fit: contain;
    }

    p{
      background-color: #218CF7;
      width:100%;
      height:40px;
      line-height:40px;
      color: #fff; 
      font-size: 14px;
    }
  }
}
`;

const SubjectItem = ({ data }) => {
  return (
    <Link to={`/academic/${data.title}`}>
      <Item>
        <img src={data.iconImageUrl} alt="logo-icon" />
        <p>{data.title}</p>
      </Item>
    </Link>
  );
};

export default SubjectItem;
