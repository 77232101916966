import React, { useState } from "react";
import "../css/funded-course.css";
import fundedCourse from "../data/fundedCourse/fundedCourse.json";
import FoundedCourseItem from "../components/FoundedCourseItem";

const FundedCoursePage = () => {
  const [fundedCourseState] = useState(fundedCourse);

  const fundedCourseList = fundedCourseState
    .filter((data) => {
      const now = new Date().toISOString().split("T")[0];
      const targetDate = new Date(data.endDate).toISOString().split("T")[0];
      return now <= targetDate;
    })
    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    .map((fundedCourseData, index) => {
      return (
        <FoundedCourseItem
          fundedCourse={fundedCourseData}
          key={index}
        ></FoundedCourseItem>
      );
    });

  return (
    <main id="main-funded-course">
      <section>
        <div>
          <div id="design-line"></div>
          <p>곧 개강하는 국비지원과정</p>
        </div>

        <div id="funded-course-container">{fundedCourseList}</div>
      </section>
    </main>
  );
};

export default FundedCoursePage;
